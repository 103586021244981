import React from 'react';
import {useConfig} from 'config';
import './GroupInfoLink.less';


export default function GroupInfoLink({group}) {
  const {getGroupUrl} = useConfig();
  console.log('getGroupUrl', getGroupUrl);

  return (
    <div className='group-info-link'>
      <a href={getGroupUrl(group)} target='_blank'>More info</a>
    </div>
  );
}