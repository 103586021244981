import URL, {parse} from 'url';

/**
 * Constructs a URL with specified filters and scopes
 *
 * @param string baseUrl the URL to construct
 * @param object filters an object with filters to apply to the URL
 * @param object scopeFilters an object with filters that determine which fields are visible
 * @return string the URL with filters applied
 */
export function applyFiltersToUrl(baseUrl, filters, scopeFilters = {}) {
  let url = URL.parse(baseUrl, true);
  let finalFilters = {...url.query};
  delete url.search;
  const availableScopeFilters = [];

  if (scopeFilters){
    for (const item in scopeFilters){
      if (scopeFilters.hasOwnProperty(item)) {
        availableScopeFilters.push(item);
      }
    }
  }
  if (filters){
    for (const key in filters){
      if (filters.hasOwnProperty(key) && filters[key] !== null && filters[key] !== undefined) {
        if(typeof filters[key] === 'object' && filters[key] !== null){
          for (const operator in filters[key]) {
            switch (operator) {
              case 'ne':
                finalFilters[key + '!'] = filters[key][operator];
                break;
              case 'eq':
                finalFilters[key] = filters[key][operator];
                break;
              case 'le':
                finalFilters[key + '<'] = filters[key][operator];
                break;
              case 'ge':
                finalFilters[key + '>'] = filters[key][operator];
                break;
            }
          }
        } else {
          if (availableScopeFilters.includes(key)) {
            // Let's compare the scope filter to the current filter, and remove anything that's not in the scope.
            let workingList = filters[key].split(',');
            let scopeList = scopeFilters[key].split(',');
            let sharedResult = workingList.filter(item => scopeList.includes(item));
            if (sharedResult.length === 0){
              finalFilters[key] = [0];
            } else{
              finalFilters[key] = sharedResult.join(',');
            }
          } else {
          finalFilters[key] = filters[key];
          }
        }
      }
    }
  }

  url.query = finalFilters;
  return URL.format(url);
}


export {URL};


export function getQueryParam(param, defaultValue = null) {
  return parse(location.href, true).query[param] || defaultValue;
}