import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import {motion} from 'framer-motion';
import './Spinner.less';


export default function Spinner(props = {}) {
  return (
    <motion.div className='groups-spinner'
        initial={{height: 0, opacity: 0}}
        animate={{height: 'auto', opacity: 1}}
        exit={{height: 0, opacity: 0}}>
      <div>
        <PropagateLoader color='#7c98ab' {...props} />
      </div>
    </motion.div>
  );
}