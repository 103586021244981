import React, {useState, useRef, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import Form, {Field, Input} from 'ui/Form';
import PhoneInput from 'ui/Form/PhoneInput';
import {validators} from 'validation';
import CloseButton from './CloseButton';
import {useConfig} from 'config';
import Spinner from './Spinner';
import {AnimatePresence} from 'framer-motion';
import {useRoutingContext} from './App';
import './GroupRoute.less';
import { useToast } from './Toast';


export default function GroupSignupRoute({location}) {
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {apiPath} = useConfig();
  const {group} = location.state;
  const contact = group.Contact;
  const {prefix} = useRoutingContext();
  const toast = useToast();

  const onSubmit = useCallback(async event => {
    event.preventDefault();
    form.current.setSubmitted(true);

    if (form.current.isValid()) {
      const data = form.current.getData();
      setLoading(true);

      const response = await fetch(`${apiPath}/groups/${group.Group_ID}/signup`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      setLoading(false);

      if (response.ok) {
        history.push(`${prefix}/group/${group.Group_ID}/signup/success`, {group});
      }
      else if (response.status === 406) {
        const errors = await response.json();
        form.current.setErrors(errors);
      }
      else {
        toast.error("An unexpected error occurred.");
      }
    }
  }, [group.Group_ID, prefix]);

  return (
    <div className='route group-route'>
      <CloseButton />
      <h3>{group.Group_Name}</h3>
      <h4>Sign Up</h4>
      <form noValidate onSubmit={onSubmit}>
        <Form ref={form}>
          <Field name='First_Name' label='First Name'
              validators={[validators.required()]}>
            <Input />
          </Field>
          <Field name='Last_Name' label='Last Name'
              validators={[validators.required()]}>
            <Input />
          </Field>
          <Field name='Email_Address' label='Email'
              validators={[validators.required(), validators.email()]}>
            <Input />
          </Field>
          <Field name='Phone' label='Phone'
              validators={[validators.required(), validators.phone()]}>
            <PhoneInput />
          </Field>
        </Form>
        <div className='submit'>
          <button type='submit' disabled={loading}>Submit</button>
          <AnimatePresence>
            {loading && <Spinner />}
          </AnimatePresence>
        </div>
      </form>
    </div>
  );
}