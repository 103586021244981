import React, {createContext, useContext, useEffect, useCallback, useReducer} from 'react';
import cx from 'classnames';
import {createPortal} from 'react-dom';
import {combineReducers} from 'redux';
import uuid from 'uuid/v4';
import {motion, AnimatePresence} from 'framer-motion';
import './Toast.less';


export const SHORT = 2500;
export const LONG = 3500;


const ToastContext = createContext({
  show() {},
  error() {}
});


export function useToast() {
  return useContext(ToastContext);
}


const reducer = function(state = [], action) {
  switch (action.type) {
    case 'add':
      return [...state, action.toast];
    case 'remove':
      return state.filter(i => i.id !== action.id);
  }
}


const variants = {
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};


function Toast({toast, remove}) {
  useEffect(() => {
    setTimeout(() => {
      remove(toast.id);
    }, toast.duration);
  }, []);

  return (
    <motion.div className={cx('watch-parties-toast', {error: toast.error})}
        variants={variants}
        animate='in'
        exit='out'
        initial='out'>
      {toast.message}
    </motion.div>
  );
}


export default function ToastProvider({children}) {
  const [toasts, dispatch] = useReducer(reducer, []);

  const show = useCallback((message, duration = LONG) => {
    dispatch({
      type: 'add',
      toast: {
        id: uuid(),
        message,
        duration,
        error: false
      }
    });
  });

  const error = useCallback((message, duration = LONG) => {
    dispatch({
      type: 'add',
      toast: {
        id: uuid(),
        message,
        duration,
        error: true
      }
    });
  });

  const remove = useCallback(id => {
    dispatch({
      type: 'remove',
      id
    });
  });

  return (
    <ToastContext.Provider value={{show, error}}>
      {children}
      <div className="watch-parties-toasts">
        <AnimatePresence>
          {toasts.map((toast, idx) =>
            <Toast toast={toast} key={toast.id} remove={remove} />
          )}
        </AnimatePresence>
      </div>
    </ToastContext.Provider>
  );
}