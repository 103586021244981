import 'whatwg-fetch';
import assign from 'object-assign';
import 'es6-promise/auto';

Object.assign = assign;


if (!String.prototype.startsWith) {
  String.prototype.startsWith = function startsWith(search, pos) {
    pos = !pos || pos < 0 ? 0 : +pos;
    return this.substring(pos, pos + search.length) === search;
  };
}
