import React, {PureComponent, createContext, forwardRef, createRef, useContext} from 'react';
import cx from 'classnames';
import uuid from 'uuid/v4';
import {validate} from 'validation';
import './index.less';


const FormContext = createContext({
  registerField: () => {},
  deregisterField: () => {},
  onChange: () => {},
  addListener: () => {},
  removeListener: () => {},
  fields: [],
  submitted: false
});
const FieldContext = createContext({
  setValue: () => {},
  defaultValue: null
});


export function useField() {
  return useContext(FieldContext);
}


export default class Form extends PureComponent {
  state = {
    submitted: false
  };

  fields = {};
  listeners = [];
  _mounted = false;

  getData = () => {
    const data = {};
    for (let i in this.fields) {
      if (this.fields.hasOwnProperty(i)) {
        data[i] = this.fields[i].getValue();
      }
    }
    return data;
  };

  getErrors = () => {
    const data = {};
    for (let i in this.fields) {
      if (this.fields.hasOwnProperty(i)) {
        data[i] = this.fields[i].getErrors();
      }
    }
    return data;
  };

  setData = (data) => {
    for (let fieldName in data) {
      if (data.hasOwnProperty(fieldName) && this.fields.hasOwnProperty(fieldName)) {
        this.fields[fieldName].setValue(data[fieldName]);
      }
    }
  };

  addListener = listener => {
    this.listeners.push(listener);
  };

  removeListener = listener => {
    this.listeners = this.listeners.filter(i => i !== listener);
  };

  setErrors = (errors) => {
    for (let fieldName in errors) {
      if (errors.hasOwnProperty(fieldName) && this.fields.hasOwnProperty(fieldName)) {
        this.fields[fieldName].setErrors(errors[fieldName]);
      }
    }
  };

  setSubmitted = (submitted = true) => {
    this.setState({submitted});
  };

  isValid = () => {
    for (let fieldName in this.fields) {
      if (this.fields.hasOwnProperty(fieldName)) {
        if (!this.fields[fieldName].isValid()) {
          return false;
        }
      }
    }
    return true;
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const {children, className, ...props} = this.props;
    const fieldRegistration = {
      registerField: this._registerField,
      deregisterField: this._deregisterField,
      addListener: this.addListener,
      removeListener: this.removeListener,
      getData: this.getData,
      fields: this.fields,
      onChange: this._onChange
    };

    return (
      <FormContext.Provider value={{...this.state, ...fieldRegistration}}>
        <div {...props} className={cx("form", className)}>
          {children}
        </div>
      </FormContext.Provider>
    );
  }

  _registerField = field => {
    this.fields[field.props.name] = field;
    if (this._mounted) {
      this._onChange();
    }
  };

  _deregisterField = field => {
    delete(this.fields[field.props.name]);
    if (this._mounted) {
      this._onChange();
    }
  };

  _onChange = () => {
    if (this.props.onChange) {
      this.props.onChange();
    }
    this.listeners.forEach(listener => listener());
  };

  _onSubmit = event => {
    event.preventDefault();
    this.setState({submitted: true}, () => {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    });
  };
}


export function withForm(WrappedComponent) {
  class WithForm extends PureComponent {
    render() {
      const {forwardedRef, ...props} = this.props;
      return (
        <FormContext.Consumer>
          {form =>
            <WrappedComponent ref={forwardedRef} {...props} form={form} />
          }
        </FormContext.Consumer>
      );
    }
  };

  return forwardRef((props, ref) => {
    return <WithForm {...props} forwardedRef={ref} />;
  });
}


export function withField(WrappedComponent) {
  class WithField extends PureComponent {
    render() {
      const {forwardedRef, ...props} = this.props;

      return (
        <FieldContext.Consumer>
          {field =>
            <WrappedComponent ref={forwardedRef} {...props} field={field} />
          }
        </FieldContext.Consumer>
      );
    }
  }

  return forwardRef((props, ref) => {
    return <WithField {...props} forwardedRef={ref} />;
  });
}


@withForm
export class FieldGroup extends PureComponent {
  fields = {};

  componentDidMount() {
    this.props.form.registerField(this);
  }

  componentWillUnmount() {
    this.props.form.deregisterField(this);
  }

  getValue = () => {
    const data = {};
    for (let i in this.fields) {
      if (this.fields.hasOwnProperty(i)) {
        data[i] = this.fields[i].getValue();
      }
    }
    return data;
  };

  getErrors = () => {
    const data = {};
    for (let i in this.fields) {
      if (this.fields.hasOwnProperty(i)) {
        data[i] = this.fields[i].getErrors();
      }
    }
    return data;
  };

  setValue = (value) => {
    for (let fieldName in value) {
      if (value.hasOwnProperty(fieldName) && this.fields.hasOwnProperty(fieldName)) {
        this.fields[fieldName].setValue(value[fieldName]);
      }
    }
  };

  setErrors = (errors) => {
    for (let fieldName in errors) {
      if (errors.hasOwnProperty(fieldName) && this.fields.hasOwnProperty(fieldName)) {
        this.fields[fieldName].setErrors(errors[fieldName]);
      }
    }
  };

  isValid = () => {
    for (let fieldName in this.fields) {
      if (this.fields.hasOwnProperty(fieldName)) {
        if (!this.fields[fieldName].isValid()) {
          return false;
        }
      }
    }
    return true;
  };

  render() {
    const {children, form, ...props} = this.props;
    const providerValue = {
      registerField: this._registerField,
      deregisterField: this._deregisterField,
      onChange: this._onChange,
      fields: this.fields,
      submitted: props.submitted || form.submitted
    };

    return (
      <FormContext.Provider value={providerValue}>
        {children}
      </FormContext.Provider>
    );
  }

  _registerField = field => {
    this.fields[field.props.name] = field;
  };

  _deregisterField = field => {
    delete(this.fields[field.props.name]);
  };

  _onChange = () => {
    if (this.props.onChange) {
      this.props.onChange();
    }
    this.props.form.onChange();
  }
}


@withForm
export class FieldArray extends PureComponent {
  fields = [];

  componentDidMount() {
    this._mounted = true;
    this.props.form.registerField(this);
  }

  componentWillUnmount() {
    this._mounted = false;
    this.props.form.deregisterField(this);
  }

  getValue = () => {
    return this.fields.map(i => i.getValue());
  };

  getErrors = () => {
    return this.fields.map(i => i.getErrors());
  };

  setErrors = (errors = []) => {
    errors.forEach((i, n) => {
      if (this.fields[n]) {
        this.fields[n].setErrors(i);
      }
    });
  };

  setValue = (values = []) => {
    values.forEach((i, n) => {
      if (this.fields[n]) {
        this.fields[n].setValue(i);
      }
    });
  };

  isValid = () => {
    return this.fields
      .map(i => i.isValid())
      .filter(i => i === false).length === 0;
  };

  render() {
    const {children, form, className, style, ...props} = this.props;
    const providerValue = {
      registerField: this._registerField,
      deregisterField: this._deregisterField,
      onChange: this._onChange,
      fields: this.fields,
      submitted: props.submitted || form.submitted
    };

    return (
      <FormContext.Provider value={providerValue}>
        <div className={cx("field-array", className)} style={style}>
          {children}
        </div>
      </FormContext.Provider>
    );
  }

  _registerField = field => {
    this.fields.push(field);
    if (this._mounted) {
      this._onChange();
    }
  };

  _deregisterField = field => {
    this.fields = this.fields.filter(i => i !== field);
    if (this._mounted) {
      this._onChange();
    }
  };

  _onChange = () => {
    if (this.props.onChange) {
      this.props.onChange();
    }
    this.props.form.onChange();
  };
}


@withForm
export class DisplayField extends PureComponent {
  id = uuid();

  render() {
    const id = this.id;
    const {label, value, errors = [], className, style, children, form, id: domId} = this.props;
    const fieldData = {
      setValue() {},
      defaultValue: value,
      value,
      errors,
      id
    };

    return (
      <FieldContext.Provider value={fieldData}>
        <div id={domId} className={cx("field", {"has-errors": form.submitted && errors.length > 0}, className)} style={style}>
          {label && <label htmlFor={`input-${id}`}>{label}</label>}
          <div className="input">
            {children}
          </div>
          {(form.submitted && errors.length > 0) &&
            <div className="errors">
              {errors.map(i =>
                <div className="error" key={i}>{i}</div>
              )}
            </div>
          }
        </div>
      </FieldContext.Provider>
    );
  }
}


@withForm
export class Field extends PureComponent {
  el = createRef();

  state = {
    id: uuid(),
    value: ('defaultValue' in this.props) ? this.props.defaultValue : null,
    errors: validate(this.props.defaultValue, this.props.validators || [])
  };

  listeners = [];

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValue !== this.props.defaultValue && this.props.defaultValue !== this.state.value) {
      this.setState({
        value: this.props.defaultValue || null,
        errors: validate(this.props.defaultValue, this.props.validators || [])
      });
    }
  }

  componentDidMount() {
    this.props.form.registerField(this);
    this.el.current.setValue = this.setValue;
  }

  componentWillUnmount() {
    this.props.form.deregisterField(this);
    if (this.el.current) {
      delete this.el.current.setValue;
    }
  }

  getValue = () => {
    return this.state.value;
  };

  getErrors = () => {
    return this.state.errors;
  };

  setErrors = (errors = []) => {
    this.setState({
      errors
    });
  };

  addListener = listener => {
    this.listeners.push(listener);
  };

  removeListener = listener => {
    this.listeners = this.listeners.filter(i => i != listener);
  };

  setValue = (value = null, fn = null) => {
    this.setState({
      value: value,
      errors: validate(value || null, this.props.validators || [])
    }, () => {
      if (fn) {
        fn();
      }
      this.props.form.onChange();
      if (this.props.onChange) {
        this.props.onChange();
      }
      this.listeners.forEach(listener => listener());
    });
  };

  isValid = () => {
    return this.state.errors.length === 0;
  };

  render() {
    const {
      id: domId,
      defaultValue,
      form,
      className,
      label,
      name,
      style,
      children,
      validators,
      required,
      ...props
    } = this.props;
    const {id, errors} = this.state;
    const fieldData = {
      setValue: this.setValue,
      defaultValue: defaultValue
    };

    return (
      <FieldContext.Provider value={{...this.state, ...fieldData}}>
        <div id={domId} className={cx("field", {"has-errors": form.submitted && errors.length > 0, required}, className)} style={style}
          ref={this.el}>
          {label && <label htmlFor={`input-${id}`}>{label}</label>}
          <div className="input">
            {children}
          </div>
          {(form.submitted && errors.length > 0) &&
            <div className="errors">
              {errors.map(i =>
                <div className="error" key={i}>{i}</div>
              )}
            </div>
          }
        </div>
      </FieldContext.Provider>
    );
  }
}


@withField
export class Input extends PureComponent {
  render() {
    const {type = 'text', field, className, style, ...props} = this.props;

    return (
      <input {...props} type={type} className={className} style={style}
          value={field.value || ''} onChange={this._onChange} id={`input-${field.id}`} />
    );
  }

  _onChange = (event) => {
    this.props.field.setValue(event.target.value);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };
}


@withField
export class DropDown extends PureComponent {
  render() {
    const {field, placeholder = "---", options = [], className, ...props} = this.props;

    return (
      <select className={className} value={JSON.stringify(field.value)} onChange={this._onChange}
          id={`dropdown-${field.id}`}>
        {placeholder && <option value="null">{placeholder}</option>}
        {options.map(i => {
          const encodedValue = JSON.stringify(i.value);
          return <option key={encodedValue} value={encodedValue}>{i.label}</option>;
        })}
      </select>
    );
  }

  _onChange = event => {
    this.props.field.setValue(JSON.parse(event.target.value));
  }
}


class CheckboxItem extends PureComponent {
  render() {
    const {id, value, onChange, checked, children} = this.props;

    return (
      <div className="checkbox">
        <input type="checkbox" id={id} checked={checked} value={value}
          onChange={event => onChange(JSON.parse(value), event.target.checked)} />
        <label htmlFor={id}>{children}</label>
      </div>
    );
  }
}


@withField
export class Checkbox extends PureComponent {
  render() {
    const {field, options = [], className, ...props} = this.props;

    return (
      <div className={cx("checkbox-input", className)}>
        {options.map((i, n) => {
          const encodedValue = JSON.stringify(i.value);
          return <CheckboxItem id={`${field.id}-${n}`} key={encodedValue}
            value={encodedValue} checked={(field.value || []).indexOf(i.value) !== -1}
            onChange={this._onChange}>{i.label}</CheckboxItem>;
        })}
      </div>
    );
  }

  _onChange = (itemValue, checked) => {
    const {field} = this.props;
    let {setValue, value} = field;
    value = (value || []).filter(i => i !== itemValue);
    if (checked) {
      value.push(itemValue);
    }
    setValue(value.length ? value : null);
  };
}


class RadioItem extends PureComponent {
  render() {
    const {id, value, onChange, checked, children} = this.props;

    return (
      <div className="radio">
        <input type="radio" id={id} checked={checked} value={value}
          onChange={event => onChange(JSON.parse(value), event.target.checked)} />
        <label htmlFor={id}>{children}</label>
      </div>
    );
  }
}


@withField
export class Radio extends PureComponent {
  render() {
    const {
      field,
      options = [],
      className,
      compare = (a, b) => a === b,
      ...props
    } = this.props;

    return (
      <div className={cx("radio-input", className)}>
        {options.map((i, n) => {
          const encodedValue = JSON.stringify(i.value);
          return <RadioItem id={`${field.id}-${n}`} key={encodedValue}
            value={encodedValue} checked={compare(field.value, i.value)}
            onChange={this._onChange}>{i.label}</RadioItem>;
        })}
      </div>
    );
  }

  _onChange = (itemValue, checked) => {
    this.props.field.setValue(itemValue);
  };
}


@withField
export class TextArea extends PureComponent {
  render() {
    const {field, className, ...props} = this.props;

    return (
      <textarea {...props} className={className} value={field.value || ''}
        id={`textarea-${field.id}`} onChange={this._onChange} />
    );
  }

  _onChange = ({target}) => {
    this.props.field.setValue(target.value);
  };
}


@withForm
export class Value extends PureComponent {
  state = {
    value: null
  };

  _getField = () => {
    const {field: fieldName, form} = this.props;
    const field = form.fields[fieldName];
    return field || null;
  };

  _updateValue = () => {
    const field = this._getField();
    if (field) {
      this.setState({
        value: field.getValue()
      })
    }
  };

  componentDidMount() {
    const field = this._getField();
    this._updateValue();
    if (field) {
      field.addListener(this._updateValue);
    }
  }

  componentWillUnmount() {
    const field = this._getField();
    if (field) {
      field.removeListener(this._updateValue);
    }
  }

  render() {
    const {children} = this.props;
    const {value} = this.state;
    return children(value);
  }
}


@withForm
export class FormData extends PureComponent {
  state = {
    data: null
  };

  componentDidMount() {
    this.props.form.addListener(this._onChange);
    this.setState({
      data: this.props.form.getData()
    });
  }

  componentWillUnmount() {
    this.props.form.removeListener(this._onChange);
  }

  render() {
    return this.props.children(this.state.data);
  }

  _onChange = () => {
    this.setState({
      data: this.props.form.getData()
    });
  };
}


export function withFormData(WrappedComponent) {
  class WithFormData extends PureComponent {
    render() {
      const {children, forwardedRef, ...props} = this.props;

      return (
        <FormData>
          {data =>
            <WrappedComponent ref={forwardedRef} {...props} formData={data}>{children}</WrappedComponent>
          }
        </FormData>
      );
    }
  }

  return forwardRef((props, ref) => (
    <WithFormData {...props} forwardedRef={ref} />
  ));
}


export class IfField extends PureComponent {
  render() {
    const {field, value, children} = this.props;

    return (
      <Value field={field}>
        {this._ifValue}
      </Value>
    );
  }

  _ifValue = fieldValue => {
    const {value, children} = this.props;
    if (fieldValue === value) {
      return children;
    }
    return null;
  };
}