import React from 'react';
import './MeetsOnlineFlag.less';


export default function MeetsOnlineFlag() {
  return (
    <div className='meets-online-flag'>
      Meets Online
    </div>
  );
}