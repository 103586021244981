import React, {useCallback, useEffect} from 'react';
import cx from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';
import './ListSwitcher.less';


export default function ListSwitcher() {
  const history = useHistory();
  const location = useLocation();
  const activeTab = location.pathname.slice(0, 5) === '/list' ? 'list' : 'map';

  const goMap = useCallback((event) => {
    event.preventDefault();
    history.push('/map');
  });

  const goList = useCallback((event) => {
    event.preventDefault();
    history.push('/list');
  });

  return (
    <div className='list-switcher'>
      <button onClick={goMap} className={cx({active: activeTab === 'map'})}>Map</button>
      <button onClick={goList} className={cx({active: activeTab === 'list'})}>List</button>
    </div>
  );
}