import React from 'react';
import CloseButton from './CloseButton';


export default function GroupSignupSuccessRoute({location}) {
  const {group} = location.state;

  return (
    <div className='route group-route'>
      <CloseButton />
      <h3>{group.Group_Name}</h3>
      <p>
        Thank you for signing up!
      </p>
    </div>
  );
}