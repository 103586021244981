import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import CloseButton from './CloseButton';
import './CongregationRoute.less';


export default function CongregationRoute() {
  const location = useLocation();
  const {congregation} = location.state;
  const {Address: a} = congregation;

  return (
    <div className="route congregation-route">
      <CloseButton />
      <h3>{congregation.Congregation_Name}</h3>
      <p>
        {a.Address_Line_1}<br />
        {a.City}, {a.State}, {a.Postal_Code}
      </p>
    </div>
  );
}