import 'polyfills';
import React from 'react';
import {render} from 'react-dom';
import App from './ui/App';
export * from './flags';


export function init(el, options = {}) {
  if (typeof el === 'string') {
    el = document.querySelector(el);
  }
  render(<App {...options} />, el);
}