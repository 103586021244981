import React, {createContext, useContext} from 'react';


const ConfigContext = createContext({
  apiPath: null,
  scope: [],
  showAddGroup: true,
  showFilters: true,
  getGroupUrl(group) {
    return 'about:blank';
  }
});


const scripts = document.querySelectorAll('script');
const thisScript = document.currentScript || scripts[scripts.length - 1];
const urlMatch = /(.*?)(\/app\/[^\\]+)\.js/.exec(thisScript.src);
let apiPath;

if (urlMatch) {
  apiPath = `${urlMatch[1]}/api`;
  __webpack_public_path__ = `${urlMatch[1]}/app/`;
}
else {
  apiPath = '/api';
  __webpack_public_path__ = `/app/`;
}


const config = {
  apiPath,
  getGroupUrl(group) {
    return 'about:blank';
  },
  scope: [],
  showAddGroup: true,
  showFilters: true
};


export function getConfig() {
  return config;
}


export function useConfig() {
  return useContext(ConfigContext);
}


export function Config({children, ...options}) {
  const context = {...config, ...options};
  return (
    <ConfigContext.Provider value={context}>
      {children}
    </ConfigContext.Provider>
  );
}