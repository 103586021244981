import React, {createContext, useContext, useReducer} from 'react';
import {combineReducers} from 'redux';


const filtersReducer = combineReducers({
  Congregation_ID(state = null, action) {
    switch (action.type) {
      case 'set-congregation':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Group_Type_ID(state = null, action) {
    switch (action.type) {
      case 'set-group-type':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Life_Stage_ID(state = null, action) {
    switch (action.type) {
      case 'set-life-stage':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Group_Focus_ID(state = null, action) {
    switch (action.type) {
      case 'set-group-focus':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Meeting_Day_ID(state = null, action) {
    switch (action.type) {
      case 'set-meeting-day':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Meets_Online(state = null, action) {
    switch (action.type) {
      case 'set-meets-online':
        return action.value ? 1 : null;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Group_Is_Full(state = 0, action) {
    switch (action.type) {
      case 'set-group-is-full':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  q(state = null, action) {
    switch (action.type) {
      case 'set-query':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Community_Group_Type_ID(state = null, action) {
    switch (action.type) {
      case 'set-community-group-type':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Community_Congregation_ID(state = null, action) {
    switch (action.type) {
      case 'set-community-congregation':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Community_Life_Stage_ID(state = null, action) {
    switch (action.type) {
      case 'set-community-life-stage':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Community_Meeting_Day_ID(state = null, action) {
    switch (action.type) {
      case 'set-community-meeting-day':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Community_Group_Is_Full(state = 0, action) {
    switch (action.type) {
      case 'set-community-group-is-full':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
  Community_Meets_Online(state = null, action) {
    switch (action.type) {
      case 'set-community-meets-online':
        return action.value;
      case 'reset':
        return null;
      default:
        return state;
    }
  },
});


const FilterContext = createContext({
  filters: {
    Congregation_ID: null,
    Group_Type_ID: null,
    Life_Stage_ID: null,
    Group_Focus_ID: null,
    Meeting_Day_ID: null,
    Community_Group_Type_ID: null,
    Community_Congregation_ID: null,
    Community_Life_Stage_ID: null,
    Community_Meeting_Day_ID: null,
    Community_Group_Is_Full: 0,
    Community_Meets_Online: null,
    q: null
  },
  dispatch() {}
});


export function useFilters() {
  return useContext(FilterContext);
}


export default function Filters({
  Congregation_ID = null,
  Group_Type_ID = '10,1,12',
  Life_Stage_ID = null,
  Group_Focus_ID = null,
  Meeting_Day_ID = null,
  Group_Is_Full = 0,
  q = null,
  Community_Group_Type_ID = '1',
  Community_Congregation_ID = '10',
  Community_Life_Stage_ID = null,
  Community_Meeting_Day_ID = null,
  Community_Group_Is_Full = 0,
  Community_Meets_Online = null,
  children
}) {
  const [state, dispatch] = useReducer(filtersReducer, {
    Group_Type_ID,
    Life_Stage_ID,
    Group_Focus_ID,
    Meeting_Day_ID,
    Group_Is_Full,
    Congregation_ID,
    Community_Group_Type_ID,
    Community_Congregation_ID,
    Community_Life_Stage_ID,
    Community_Meeting_Day_ID,
    Community_Group_Is_Full,
    Community_Meets_Online,
    q
  });
  const context = {
    filters: state,
    dispatch
  };

  return (
    <FilterContext.Provider value={context}>
      {children}
    </FilterContext.Provider>
  );
}