import React, {Fragment, useState, useCallback, useEffect} from 'react';
import cx from 'classnames';
import moment from 'moment';
import {useConfig} from 'config';
import {applyFiltersToUrl} from 'utils';
import {useFilters} from './Filters';
import {FilterControls, useScopeFilters} from './Map';
import Spinner from './Spinner';
import {GroupSignupForm, ContactForm, GroupInquiryForm} from './GroupRoute';
import MeetsOnlineFlag from './MeetsOnlineFlag';
import GroupInfoLink from './GroupInfoLink';
import './List.less';


const groupTypePins = {
  10: 'watch-party-pin',
  1: 'group-pin',
  12: 'family-pin'
};


function Pin({group}) {
  return <div className={cx('map-pin', groupTypePins[group.Group_Type_ID])} />;
}

function CommunityGroup({group}) {
  const contact = group.Contact;
  const addr = group.Address;
  const time = moment(`2020-01-01T${group.Meeting_Time}`);

  return (
    <Fragment>
      {group.Meets_Online && <MeetsOnlineFlag />}
      <Pin group={group} />
      <h3 className='group-name'>{group.Group_Name}</h3>
      <div className='group-details'>
        <div className='group-leader'>Led by {contact.Nickname || contact.First_Name} {contact.Last_Name[0]}.</div>
        <div className='group-congregation'>{group.Congregation.Congregation_Name}</div>
        <div className='group-time'>{group.Meeting_Day} at {time.format('h:mma')}</div>
        <GroupInfoLink group={group} />
      </div>
      <GroupSignupForm group={group} />
    </Fragment>
  );
}


function CPFamily({group}) {
  const contact = group.Contact;

  return (
    <Fragment>
      <Pin group={group} />
      <h3>{contact.Nickname || contact.First_Name} {contact.Last_Name[0]}.</h3>
      <div style={{flex: '1 0 15px'}} />
      <ContactForm group={group} />
    </Fragment>
  );
}


function WatchParty({group}) {
  const contact = group.Contact;
  const addr = group.Address;

  return (
    <Fragment>
      <Pin group={group} />
      <h3 className='group-name'>{group.Group_Name}</h3>
      <div className='group-details'>
        <div className='group-leader'>Led by {contact.Nickname || contact.First_Name} {contact.Last_Name[0]}.</div>
        <div className='group-location'>{addr.City}, {addr.State} {addr.Postal_Code}</div>
        <GroupInfoLink group={group} />
      </div>
      <GroupInquiryForm group={group} />
    </Fragment>
  );
}


const GroupTypeMap = {
  10: WatchParty,
  1: CommunityGroup,
  12: CPFamily
}


function Grid() {
  const [url, setUrl] = useState(null);
  const {apiPath} = useConfig();
  const scopeFilters = useScopeFilters();
  const filters = useFilters();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUrl(applyFiltersToUrl(`${apiPath}/groups`, filters.filters, scopeFilters));
  }, [JSON.stringify(filters.filters), JSON.stringify(scopeFilters)]);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then(response => response.json())
        .then(({data}) => {
          setGroups(data);
          setLoading(false);
        });
    }
  }, [url]);

  return (
    <div className='groups-grid'>
      {loading && <Spinner />}
      {groups.map(group =>
        <Group key={group.Group_ID} group={group} />
      )}
    </div>
  );
}


function Group({group}) {
  const GroupType = GroupTypeMap[group.Group_Type_ID];

  return (
    <div className='group-grid-item'>
      <GroupType group={group} />
    </div>
  );
}


export default function List() {
  return (
    <div className='route list-route'>
      <div className='list-wrapper'>
        <Grid />
      </div>
      <FilterControls />
    </div>
  );
}