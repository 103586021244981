import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {MdClose} from 'react-icons/md';
import {useRoutingContext} from './App';
import './CloseButton.less';


export default function CloseButton({path = '/'}) {
  const {prefix} = useRoutingContext();
  const history = useHistory();
  const onClick = useCallback(event => {
    event.preventDefault();
    history.push(`${prefix}${path}`);
  }, [path, prefix]);

  return (
    <button className='close-button' onClick={onClick}>
      <MdClose />
    </button>
  );
}