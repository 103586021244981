import React, {Fragment, useState, useCallback, useEffect, useRef} from 'react';
import cx from 'classnames';
// import { GoogleMap, LoadScript, Marker, MarkerClusterer, OverlayView } from '@react-google-maps/api';
import {useHistory} from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import {fitBounds} from 'google-map-react/utils';
import {useFilters} from './Filters';
import Form, {Field, Input, TextArea} from 'ui/Form';
import PhoneInput from 'ui/Form/PhoneInput';
import {motion, AnimatePresence} from 'framer-motion';
import {FiChevronDown, FiChevronLeft, FiUsers, FiSliders, FiX, FiPlus, FiMinus} from 'react-icons/fi';
import {FaChurch, FaHome, FaUserAlt} from 'react-icons/fa';
import {applyFiltersToUrl} from 'utils';
import {useConfig} from 'config';
import useSupercluster from 'use-supercluster';
import {useLocation} from './Location';
import {validators} from 'validation';
import Spinner from './Spinner';
import {ONLINE_FAMILY, WATCH_PARTIES, GROUPS, COMMUNITY_GROUPS, LOCAL_GOOD} from '../flags';
import './Map.less';
import { useToast } from './Toast';


const MAX_ZOOM = 13;
const DEFAULT_ZOOM = 5;


function useUrl(url) {
  const {apiPath} = useConfig();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${apiPath}${url}`)
    .then(response => response.json())
    .then(({data}) => {
      setData(data);
    });
  }, [url]);

  return data;
}


function useCongregations() {
  const {apiPath} = useConfig();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${apiPath}/congregations`)
    .then(response => response.json())
    .then(({data}) => {
      setData(data);
    });
  }, []);

  return data;
}


const defaultCenter = {
  lat: 36.1762927,
  lng: -86.7836631
};


function IconWrapper({children, color}) {
  return (
    <div className='icon-wrapper'>
      {children}
    </div>
  );
}


function Filter({options, label, value, onChange, placeholder = 'View all'}) {
  const handleChange = useCallback(event => {
    onChange(JSON.parse(event.target.value));
  });

  return (
    <div className='group-type-sub-filter'>
      <label>{label}</label>
      <select value={JSON.stringify(value)} onChange={handleChange}>
        <option value='null'>{placeholder}</option>
        {options.map(i =>
          <option value={JSON.stringify(i.value)} key={JSON.stringify(i.value)}>
            {i.label}
          </option>
        )}
      </select>
      <FiChevronDown />
    </div>
  );
}


function LifeStageFilter() {
  const filters = useFilters();
  const data = useUrl('/life_stages');
  const options = data.map(i => ({
    label: i.Life_Stage,
    value: i.Life_Stage_ID
  }));

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-life-stage',
      value
    });
  });

  return <Filter options={options} label='Demographic'
      value={filters.filters.Life_Stage_ID}
      onChange={onChange} />;
}

function CommunityLifeStageFilter() {
  const filters = useFilters();
  const data = useUrl('/life_stages');
  const options = data.map(i => ({
    label: i.Life_Stage,
    value: i.Life_Stage_ID
  }));

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-community-life-stage',
      value
    });
  });

  return <Filter options={options} label='Demographic'
      value={filters.filters.Community_Life_Stage_ID}
      onChange={onChange} />;
}


function GroupFocusFilter() {
  const filters = useFilters();
  const data = useUrl('/group_focuses');
  const options = data.map(i => ({
    label: i.Group_Focus,
    value: i.Group_Focus_ID
  }));

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-group-focus',
      value
    });
  });

  return <Filter options={options} label='Group Focus'
      value={filters.filters.Group_Focus_ID}
      onChange={onChange} />;
}


function CongregationFilter() {
  const filters = useFilters();
  const data = useUrl('/congregations');
  const options = data.map(i => ({
    value: i.Congregation_ID,
    label: i.Congregation_Name
  }));

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-congregation',
      value
    });
  });

  return <Filter options={options} label='Campus'
      value={filters.filters.Congregation_ID}
      onChange={onChange} />;
}


function MeetingDayFilter() {
  const filters = useFilters();
  const data = useUrl('/meeting_days');
  const options = data.map(i => ({
    label: i.Meeting_Day,
    value: i.Meeting_Day_ID
  }));

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-meeting-day',
      value
    });
  });

  return <Filter options={options} label='Meeting Day'
      value={filters.filters.Meeting_Day_ID}
      onChange={onChange} />;
}

function CommunityMeetingDayFilter() {
  const filters = useFilters();
  const data = useUrl('/meeting_days');
  const options = data.map(i => ({
    label: i.Meeting_Day,
    value: i.Meeting_Day_ID
  }));

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-community-meeting-day',
      value
    });
  });

  return <Filter options={options} label='Meeting Day'
      value={filters.filters.Community_Meeting_Day_ID}
      onChange={onChange} />;
}


function MeetsOnlineFilter() {
  const filters = useFilters();

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-meets-online',
      value
    });
  });

  const options = [
    {value: 1, label: 'Group meets online'}
  ];

  return <Filter options={options} label='Meets Online'
      value={filters.filters.Meets_Online}
      onChange={onChange} />;
}

function CommunityMeetsOnlineFilter() {
  const filters = useFilters();

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-community-meets-online',
      value
    });
  });

  const options = [
    {value: 0, label: 'In Person Only'},
    {value: 1, label: 'Virtual Only'}
  ];

  return <Filter options={options} label='In Person / Virtual'
      value={filters.filters.Community_Meets_Online}
      onChange={onChange} />;
}


function OpenGroupsFilter() {
  const filters = useFilters();

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-group-is-full',
      value
    });
  });

  const options = [
    {value: 0, label: 'View Only Open Groups'}
  ];

  return <Filter options={options} label='Open Groups'
      value={filters.filters.Group_Is_Full}
      onChange={onChange} />;
}

function CommunityOpenGroupsFilter() {
  const filters = useFilters();

  const onChange = useCallback(value => {
    filters.dispatch({
      type: 'set-community-group-is-full',
      value
    });
  });

  const options = [
    {value: 0, label: 'View Only Open Groups'}
  ];

  return <Filter options={options} label='Open Groups'
      value={filters.filters.Community_Group_Is_Full}
      onChange={onChange} />;
}


function IfScope({children, scope}) {
  const config = useConfig();
  if (config.scope.length && config.scope.indexOf(scope) === -1) {
    return null;
  }
  return children;
}


export function FilterControls() {
  const [open, setOpen] = useState(matchMedia('(max-width: 480px)').matches ? false : true);
  const variants = {
    open: {
      height: 'auto',
      transition: {
        ease: 'anticipate',
        type: 'tween'
      }
    },
    closed: {
      height: 58,
      transition: {
        ease: 'anticipate',
        type: 'tween'
      }
    }
  };
  const iconVariants = {
    open: {
      rotate: 0
    },
    closed: {
      rotate: 180
    }
  };
  const toggleOpen = useCallback(event => {
    setOpen(open => !open);
  });

  useEffect(() => {
    if (matchMedia('(max-width: 480px)').matches) {
      setOpen(false);
    }
  }, []);

  return (
    <motion.div className='filter-controls'
        initial={false}
        animate={open ? 'open' : 'closed'}
        variants={variants}>
      <div className='filter-controls-toggle' onClick={toggleOpen}>
        <label>Filter by...</label>
        <motion.div variants={iconVariants}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <FiChevronDown />
        </motion.div>
      </div>
      <IfScope scope={GROUPS}>
        <GroupTypeFilter value={1} label='Groups'
            icon={<IconWrapper color='#c3d25a'><Icon name='group-pin' /></IconWrapper>}>
          <CongregationFilter />
          <LifeStageFilter />
          <MeetingDayFilter />
          <MeetsOnlineFilter />
          <OpenGroupsFilter />
        </GroupTypeFilter>
      </IfScope>
      <IfScope scope={ONLINE_FAMILY}>
        <GroupTypeFilter value={12} label='Cross Point Online Family'
            icon={<IconWrapper color='#b4d6ec'><Icon name='family-pin' /></IconWrapper>} />
      </IfScope>
      <IfScope scope={WATCH_PARTIES}>
        <GroupTypeFilter value={10} label="Local Gatherings"
            icon={<IconWrapper color='#ff671f'><Icon name='watch-party-pin' /></IconWrapper>} />
      </IfScope>
      <IfScope scope={COMMUNITY_GROUPS}>
        <CommunityGroupTypeFilter value={1} label="Community Groups"
            icon={<IconWrapper color='#ff671f'><Icon name='group-pin' /></IconWrapper>}>
          <CommunityLifeStageFilter />
          <CommunityMeetingDayFilter />
          <CommunityOpenGroupsFilter />
          <CommunityMeetsOnlineFilter />
        </CommunityGroupTypeFilter>
      </IfScope>
      <IfScope scope={LOCAL_GOOD}>
        <GroupTypeFilter value={17} label="Local Good"
            icon={<IconWrapper color='#ff671f'><Icon name='local-good-pin' /></IconWrapper>} />
      </IfScope>
    </motion.div>
  );
}


function Icon({name}) {
  return <div className={cx('filter-icon', name)} />;
}


function Toggle({checked, onChange}) {
  const onClick = useCallback(event => {
    onChange();
  }, [onChange]);

  return (
    <div className={cx('group-type-toggle', {checked})} onClick={onClick}>
      <div className='toggle-bg' />
      <div className='toggle-handle' />
    </div>
  );
}


function GroupTypeFilter({value, label, icon, children}) {
  const filters = useFilters();
  const {scope} = useConfig();
  const [open, setOpen] = useState(scope.length === 1 ? true : false);
  const onChange = useCallback(() => {
    const types = filters.filters.Group_Type_ID.split(',').filter(i => i);

    filters.dispatch({
      type: 'set-group-type',
      value: (
        types.indexOf(String(value)) > -1 ? types.filter(i => i != value) : [...types, value]
      ).join(',')
    });

  }, [filters.filters.Group_Type_ID]);

  const checked = filters.filters.Group_Type_ID.split(',').indexOf(String(value)) > -1;

  const toggleOpen = useCallback(() => {
    setOpen(open => !open);
  });

  // useEffect(() => {
  //   if (matchMedia('(min-width: 1024px)').matches) {
  //     setOpen(true);
  //   }
  // }, []);

  const variants = {
    open: {
      height: 'auto',
      transition: {
        ease: 'anticipate',
        type: 'tween'
      }
    },
    closed: {
      height: 0,
      transition: {
        ease: 'anticipate',
        type: 'tween'
      }
    }
  };

  return (
    <div className='group-type-filter'>
      {scope.length !== 1 &&
        <div className='group-type-header'>
          {icon}
          <label>{label}</label>
          {children &&
            <div className='filter-toggle' onClick={toggleOpen}>
              <FiSliders />
            </div>
          }
          <Toggle checked={checked} onChange={onChange} />
        </div>
      }
      {children &&
        <motion.div className='group-type-children'
            animate={open ? 'open' : 'closed'}
            initial={'closed'}
            variants={variants}>
          <div className='group-type-children-inner'>
            {children}
          </div>
        </motion.div>
      }
    </div>
  );
}

function CommunityGroupTypeFilter({value, label, icon, children}) {
  const filters = useFilters();
  const {scope} = useConfig();
  const [open, setOpen] = useState(scope.length === 1 ? true : false);
  const onChange = useCallback(() => {
    const types = filters.filters.Community_Group_Type_ID.split(',').filter(i => i);

    filters.dispatch({
      type: 'set-community-group-type',
      value: (
        types.indexOf(String(value)) > -1 ? types.filter(i => i != value) : [...types, value]
      ).join(',')
    });

  }, [filters.filters.Community_Group_Type_ID]);

  const checked = filters.filters.Community_Group_Type_ID.split(',').indexOf(String(value)) > -1;

  const toggleOpen = useCallback(() => {
    setOpen(open => !open);
  });

  const variants = {
    open: {
      height: 'auto',
      transition: {
        ease: 'anticipate',
        type: 'tween'
      }
    },
    closed: {
      height: 0,
      transition: {
        ease: 'anticipate',
        type: 'tween'
      }
    }
  };

  return (
    <div className='group-type-filter'>
      {scope.length !== 1 &&
        <div className='group-type-header'>
          {icon}
          <label>{label}</label>
          {children &&
            <div className='filter-toggle' onClick={toggleOpen}>
              <FiSliders />
            </div>
          }
          <Toggle checked={checked} onChange={onChange} />
        </div>
      }
      {children &&
        <motion.div className='group-type-children'
            animate={open ? 'open' : 'closed'}
            initial={'closed'}
            variants={variants}>
          <div className='group-type-children-inner'>
            {children}
          </div>
        </motion.div>
      }
    </div>
  );
}


function Search({onChange}) {
  const el = useRef(null);

  useEffect(() => {
    if (window.google) {
      const searchBox = new google.maps.places.SearchBox(el.current);

      function placesChanged() {
        const places = searchBox.getPlaces();
        if (places.length && onChange && places[0].geometry && places[0].geometry.viewport) {
          onChange(places[0].geometry.viewport);
        }
      }

      searchBox.addListener('places_changed', placesChanged);
    }
  }, [window.google]);

  return (
    <div className='search-wrapper'>
      <input type='text'
          placeholder='Try "Nashville" or "37011"'
          ref={el} />
    </div>
  );
}


function UserBug() {
  return (
    <div className='filter-icon family-pin' />
  );
}


function AddSelf() {
  const {apiPath} = useConfig();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [showForm, setShowForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const form = useRef(null);
  const history = useHistory();
  const toast = useToast();

  const toggleOpen = useCallback(() => {
    setOpen(open => !open);
    setShowForm(false);
  });

  const toggleForm = useCallback(() => {
    setShowForm(showForm => !showForm);
  });

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    form.current.setSubmitted(true);

    if (form.current.isValid()) {
      setLoading(true);
      const data = form.current.getData();
      data.Location = {
        lat: String(location.coords.lat),
        lon: String(location.coords.lng)
      };

      fetch(`${apiPath}/groups`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        setLoading(false);
        if (response.ok) {
          toast.show("Your request has been received! Thank you!");
          setShowForm(false);
          setOpen(false);
        }
        else {
          toast.error("An unexpected error occurred.");
        }
      });
    }
  }, [JSON.stringify(location.coords)]);

  return (
    <Fragment>
      <motion.div className='add-self-button'
          initial={false}
          animate={open ? {
            x: 136
          } : {
            x: 0
          }}>
        <button onClick={toggleOpen}>
          <FiChevronLeft />
          <UserBug />
        </button>
      </motion.div>
      <motion.div className='add-self-overlay'
          initial={false}
          animate={open ? {
            x: 0
          } : {
            x: 462
          }}>
        <div className='add-self-overlay-close' onClick={toggleOpen}>
          <FiX />
        </div>
        <div className='add-self-overlay-inner'>
          <div className='add-self-overlay-left'>
            <UserBug />
          </div>
          {location.enabled ?
            <div className='add-self-overlay-center'>
              <h4>Put yourself on the map.</h4>
              <p>
                Connect with CP Online Family near you, or across the world.
              </p>
            </div> :
            <div className='add-self-overlay-center'>
              <h4>Enable location services.</h4>
              <p>
                To put yourself on the map, please enable
                location services.
              </p>
            </div>
          }
          {location.enabled &&
            <div className='add-self-overlay-right' onClick={toggleForm}>
            {showForm ? <FiMinus /> : <FiPlus />}
            </div>
          }
          <motion.div className='add-group-form'
              initial={false}
              animate={showForm ? {
                height: 'auto'
              } : {
                height: 0
              }}>
            <div className='add-group-form-scroll'>
            <div className='add-group-form-inner'>
              <form noValidate onSubmit={onSubmit}>
                <Form ref={form}>
                  <Field name="First_Name" label="First Name*"
                      validators={[validators.required()]}>
                    <Input />
                  </Field>
                  <Field name="Last_Name" label="Last Name*"
                      validators={[validators.required()]}>
                    <Input />
                  </Field>
                  <Field name="Email_Address" label="Email*"
                      validators={[validators.required(), validators.email()]}>
                    <Input type='email' />
                  </Field>
                  <Field name="Phone" label="Phone*"
                      validators={[validators.required(), validators.phone()]}>
                    <PhoneInput />
                  </Field>
                </Form>
                <div className='submit'>
                  <button type='submit' disabled={loading}>Submit</button>
                  <AnimatePresence>
                    {loading && <Spinner />}
                  </AnimatePresence>
                </div>
                <strong>
                  By submitting my information, I am confirming that I am
                  18 years old or older.
                </strong>
              </form>
            </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </Fragment>
  );
}


export function useScopeFilters() {
  const config = useConfig();
  const filters = {};
  if (config.scope.length) {
    const idMap = {
      [WATCH_PARTIES]: 10,
      [ONLINE_FAMILY]: 12,
      [GROUPS]: 1,
      [COMMUNITY_GROUPS]: 1,
      [LOCAL_GOOD]: 17
    };

    const values = config.scope
      .filter(i => i !== COMMUNITY_GROUPS)
      .map(i => String(idMap[i]));

    const community_values = config.scope
      .filter(i => i === COMMUNITY_GROUPS)
      .map(i => String(idMap[i]));

    filters.Group_Type_ID = values.join(',');
    filters.Community_Group_Type_ID = community_values.join(',');

  }
  return filters;
}


export default function Map() {
  const el = useRef(null);
  const {apiPath, showAddGroup} = useConfig();
  const location = useLocation();
  const [map, setMap] = useState(null);
  const [url, setUrl] = useState(null);
  const [groups, setGroups] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const filters = useFilters();
  const scopeFilters = useScopeFilters();
  const congregations = useCongregations();

  const onLoad = useCallback(map => {
    setMap(map);
    map.setOptions({
      styles: [
        {
          stylers: [
            {saturation: -100},
            {lightness: 40}
          ]
        }
      ],
      mapTypeControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP]
      },
      streetViewControl: false,
      fullscreenControl: false
    });
  });

  useEffect(() => {
    if (map) {
      const bounds = map.getBounds();
      const topLeft = `${bounds.getNorthEast().lat()},${bounds.getSouthWest().lng()}`;
      const bottomRight = `${bounds.getSouthWest().lat()},${bounds.getNorthEast().lng()}`;
      const url = applyFiltersToUrl(`${apiPath}/groups/${topLeft}|${bottomRight}`, filters.filters, scopeFilters);
      setUrl(applyFiltersToUrl(url, filters.filters, scopeFilters));
    }
  }, [JSON.stringify(filters.filters), JSON.stringify(scopeFilters)]);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then(response => response.json())
        .then(({data}) => {
          setGroups(data);
        });
    }
  }, [url]);

  const createMapOptions = maps => {
    return {
      styles: [
        {
          stylers: [
            {saturation: -100},
            {lightness: 40}
          ]
        }
      ],
      mapTypeControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP]
      },
      streetViewControl: false,
      fullscreenControl: false,
      maxZoom: MAX_ZOOM,
      zoomControlOptions: {
        // position: google.maps.ControlPosition.BOTTOM_LEFT
      }
    };
  };

  const onMapChange = useCallback(event => {
    const bounds = event.bounds;
    const topLeft = `${bounds.nw.lat},${bounds.nw.lng}`;
    const bottomRight = `${bounds.se.lat},${bounds.se.lng}`;
    const url = `${apiPath}/groups/${topLeft}|${bottomRight}`;
    setZoom(event.zoom);
    setBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat
    ]);
    setUrl(applyFiltersToUrl(url, filters.filters, scopeFilters));
  }, [JSON.stringify(filters.filters), JSON.stringify(scopeFilters)]);

  const points = groups.map(group => ({
    type: "Feature",
    properties: { cluster: false, group},
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(group.Location.lon),
        parseFloat(group.Location.lat)
      ]
    }
  }));

  const {clusters, supercluster} = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 120,
      maxZoom: MAX_ZOOM
    }
  });

  // Get the user's location and center the map on it
  useEffect(() => {
    if (map && location.enabled) {
      setZoom(DEFAULT_ZOOM);
      setCenter(location.coords);
    }
  }, [map, JSON.stringify(location)]);

  const onSearchChange = useCallback(viewport => {
    const ne = viewport.getNorthEast();
    const sw = viewport.getSouthWest();
    const {center, zoom} = fitBounds({
      ne: {
        lat: ne.lat(),
        lng: ne.lng()
      },
      sw: {
        lat: sw.lat(),
        lng: sw.lng()
      }
    }, {
      width: el.current.clientWidth,
      height: el.current.clientHeight
    });
    setCenter(center);
    setZoom(zoom);
  }, [JSON.stringify(filters.filters), JSON.stringify(scopeFilters)]);

  return (
    <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}} ref={el}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyDBWPYsTspevqAF_StbQOAjSXgvB4X6cFY',
          libraries: 'places'
        }}
        center={center}
        zoom={zoom}
        options={createMapOptions}
        onGoogleApiLoaded={({ map }) => {
          setMap(map);
        }}
        onChange={onMapChange}>
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
            group,
          } = cluster.properties;

          if (isCluster) {
            return <ClusterMarker
                key={`cluster-${longitude},${latitude}-${pointCount}`}
                lat={latitude}
                lng={longitude}
                cluster={cluster}
                supercluster={supercluster}
                map={map}
                zoom={zoom} />;
          }
          else {
            return <GroupMarker group={group}
                key={`group-${group.Group_ID}`}
                lat={group.Location.lat}
                lng={group.Location.lon} />;
          }
        })}
        {congregations.filter(i => i.Location).map(congregation =>
          <CongregationMarker key={`congregation-${congregation.Congregation_ID}`}
            congregation={congregation}
            lat={congregation.Location.lat}
            lng={congregation.Location.lon} />
        )}
      </GoogleMapReact>
      <FilterControls />
      <Search onChange={onSearchChange} />
      {showAddGroup &&
        <AddSelf />
      }
    </div>
  );
}


function CongregationMarker({congregation}) {
  const history = useHistory();
  const onClick = useCallback(event => {
    history.push(`/map/congregation/${congregation.Congregation_ID}`, {
      congregation
    });
  }, [congregation.Congregation_ID]);

  return (
    <div className='group-marker' onClick={onClick}>
      <div className='map-pin congregation-pin' />
    </div>
  );
}


function ClusterMarker({cluster, supercluster, map, zoom}) {
  const history = useHistory();
  const onClick = useCallback(() => {
    if (zoom === MAX_ZOOM) {
      const groups = supercluster.getLeaves(cluster.id).map(i => i.properties.group);
      history.push('/map/cluster', {groups});
    }
    else {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const expansionZoom = Math.min(
        supercluster.getClusterExpansionZoom(cluster.id),
        20
      );
      map.setZoom(expansionZoom);
      map.panTo({ lat: latitude, lng: longitude });
    }
  }, [cluster, map, zoom]);

  return (
    <motion.div className='cluster-marker' onClick={onClick}
      style={{scale: 0}}
      animate={{scale: 1}}
      exit={{scale: 0}}>
      {cluster.properties.point_count}
    </motion.div>
  );
}


const groupTypePins = {
  10: 'watch-party-pin',
  1: 'group-pin',
  12: 'family-pin'
};


function GroupMarker({group}) {
  const history = useHistory();
  const onClick = useCallback(event => {
    history.push(`/map/group/${group.Group_ID}`, {
      group
    });
  }, [group.Group_ID]);

  return (
    <motion.div className='group-marker'
      style={{scale: 0}}
      animate={{scale: 1}}
      exit={{scale: 0}}
      onClick={onClick}>
      <div className={cx('map-pin', groupTypePins[group.Group_Type_ID])} />
    </motion.div>
  );
}
