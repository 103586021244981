import React, {useCallback} from 'react';
import cx from 'classnames';
import {useHistory} from 'react-router-dom';
import CloseButton from './CloseButton';
import './ClusterRoute.less';


const groupTypePins = {
  10: 'watch-party-pin',
  1: 'group-pin',
  12: 'family-pin'
};


function Group({group}) {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push(`/map/group/${group.Group_ID}`, {group});
  }, [group.Group_ID]);

  return (
    <div className='cluster-group' onClick={onClick}>
      <div className={cx('filter-icon', groupTypePins[group.Group_Type_ID])} />
      {group.Group_Name}
    </div>
  );
}


export default function ClusterRoute({location, history}) {
  const {groups} = location.state;

  return (
    <div className='route group-route'>
      <CloseButton />
      <div className='cluster-groups'>
        {groups.map(group =>
          <Group group={group} key={group.Group_ID} />
        )}
      </div>
    </div>
  );
}