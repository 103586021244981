import React, {useEffect, useState, useContext, createContext} from 'react';


const LocationContext = createContext({
  coords: {
    lat: null,
    lng: null
  },
  enabled: false
});


export function useLocation() {
  return useContext(LocationContext);
}


export default function Location({children}) {
  const [location, setLocation] = useState({
    coords: {
      lat: null,
      lng: null
    },
    enabled: false
  });

  useEffect(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.getCurrentPosition(loc => {
        setLocation({
          coords: {
            lat: loc.coords.latitude,
            lng: loc.coords.longitude
          },
          enabled: true
        });
      });

      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
}