import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {FaRegEnvelope} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import CloseButton from './CloseButton';
import {useRoutingContext} from './App';
import MeetsOnlineFlag from './MeetsOnlineFlag';
import GroupInfoLink from './GroupInfoLink';
import './GroupRoute.less';


export function GroupInquiryForm({group}) {
  const history = useHistory();
  const {prefix} = useRoutingContext();

  const openInquiryForm = useCallback((event) => {
    event.preventDefault();
    history.push(`${prefix}/group/${group.Group_ID}/inquire`, {group});
  }, [group.Group_ID, prefix]);

  return (
    <div className='group-button'>
      {group.Group_Is_Full ?
        <span className="group-is-full">Group Is Full</span> :
        <button onClick={openInquiryForm}>
          <FaRegEnvelope /> Connect With Us
        </button>
      }
    </div>
  );
}


export function GroupSignupForm({group}) {
  const history = useHistory();
  const {prefix} = useRoutingContext();

  const openSignupForm = useCallback((event) => {
    event.preventDefault();
    history.push(`${prefix}/group/${group.Group_ID}/inquire`, {group});
  }, [group.Group_ID, prefix]);

  return (
    <div className='group-button'>
      {group.Group_Is_Full ?
        <span className="group-is-full">Group Is Full</span> :
        <button onClick={openSignupForm}>
          <FaRegEnvelope /> Sign Up
        </button>
      }
    </div>
  );
}


function WatchParty({group}) {
  const contact = group.Contact;
  const addr = group.Address;

  return (
    <Fragment>
      <CloseButton />
      <GroupGraphic group={group} />
      <h3 className='group-name'>{group.Group_Name}</h3>
      <div className='group-details'>
        <div className='group-leader'>Led by {contact.Nickname || contact.First_Name} {contact.Last_Name[0]}.</div>
        <div className='group-location'>{addr.City}, {addr.State} {addr.Postal_Code}</div>
        <GroupInfoLink group={group} />
      </div>
      <GroupInquiryForm group={group} />
    </Fragment>
  );
}


function GroupGraphic({group}) {
  if (group.Graphic_URL) {
    console.warn('graphic', group.Graphic_URL);
  }

  return group.Graphic_URL ? (
    <div className='group-graphic'>
      <img src={group.Graphic_URL} />
    </div>
  ) : null;
}


function CommunityGroup({group}) {
  const contact = group.Contact;
  const addr = group.Address;
  const time = moment(`2020-01-01T${group.Meeting_Time}`);

  return (
    <Fragment>
      {group.Meets_Online && <MeetsOnlineFlag />}
      <CloseButton />
      <GroupGraphic group={group} />
      <h3 className='group-name'>{group.Group_Name}</h3>
      <div className='group-details'>
        <div className='group-leader'>Led by {contact.Nickname || contact.First_Name} {contact.Last_Name[0]}.</div>
        <div className='group-congregation'>{group.Congregation.Congregation_Name}</div>
        <div className='group-time'>{group.Meeting_Day} at {time.format('h:mma')}</div>
        <GroupInfoLink group={group} />
      </div>
      <GroupSignupForm group={group} />
    </Fragment>
  );
}


export function ContactForm({group}) {
  const history = useHistory();
  const {prefix} = useRoutingContext();

  const openContactForm = useCallback((event) => {
    event.preventDefault();
    history.push(`${prefix}/group/${group.Group_ID}/contact`, {group});
  }, [group.Group_ID, prefix]);

  return (
    <div className='group-button'>
      {group.Group_Is_Full ?
        <span className="group-is-full">Group Is Full</span> :
        <button onClick={openContactForm}>
          <FaRegEnvelope /> Say Hi
        </button>
      }
    </div>
  );
}


function CPFamily({group}) {
  const contact = group.Contact;

  return (
    <Fragment>
      <CloseButton />
      <GroupGraphic group={group} />
      <h3>{contact.Nickname || contact.First_Name} {contact.Last_Name[0]}.</h3>
      <div style={{flex: '1 0 15px'}} />
      <ContactForm group={group} />
    </Fragment>
  );
}


const GroupTypeMap = {
  10: WatchParty,
  1: CommunityGroup,
  12: CPFamily
}


export default function GroupRoute({location}) {
  const {group} = location.state;
  const GroupType = GroupTypeMap[group.Group_Type_ID];

  return (
    <div className='route group-route'>
      <GroupType group={group} />
    </div>
  );  
}