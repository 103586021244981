import React, {useCallback} from 'react';
import InputMask from 'react-input-mask';
import {useField} from 'ui/Form';


export default function PhoneInput() {
  const {value, setValue} = useField();
  const onChange = useCallback(() => {
    setValue(event.target.value || null);
  });

  return (
    <InputMask mask="999-999-9999" value={value || ''} onChange={onChange} type='tel' />
  );
}