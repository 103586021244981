import React, {createContext, useContext} from 'react';
import {MemoryRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Map from './Map';
import List from './List';
import Filters from './Filters';
import {Config} from '../config';
import GroupRoute from './GroupRoute';
import GroupSignupRoute from './GroupSignupRoute';
import GroupSignupSuccessRoute from './GroupSignupSuccessRoute';
import GroupInquiryRoute from './GroupInquiryRoute';
import GroupContactRoute from './GroupContactRoute';
import CongregationRoute from './CongregationRoute';
import ClusterRoute from './ClusterRoute';
import Location from './Location';
import ListSwitcher from './ListSwitcher';
import './App.less';
import ToastProvider from './Toast';


const RoutingContext = createContext({
  prefix: '/map'
});


export function useRoutingContext() {
  return useContext(RoutingContext);
}


export default function App({filters = {}, ...options}) {
  const defaultPath = options.defaultPath || '/map';

  return (
    <div className='watch-parties-app'>
      <ToastProvider>
        <Config {...options}>
          <Location>
            <Router initialEntries={[defaultPath]} initialIndex={0}>
              <Filters {...filters}>
                <Switch>
                  <Route path='/map'>
                    <RoutingContext.Provider value={{prefix: '/map'}}>
                      <Map />
                      <Switch>
                        <Route exact path='/map/group/:groupId' component={GroupRoute} />
                        <Route exact path='/map/group/:groupId/signup' component={GroupSignupRoute} />
                        <Route exact path='/map/group/:groupId/signup/success' component={GroupSignupSuccessRoute} />
                        <Route exact path='/map/group/:groupId/inquire' component={GroupInquiryRoute} />
                        <Route exact path='/map/group/:groupId/contact' component={GroupContactRoute} />
                        <Route exact path='/map/congregation/:congregationId' component={CongregationRoute} />
                        <Route exact path='/map/cluster' component={ClusterRoute} />
                      </Switch>
                    </RoutingContext.Provider>
                  </Route>
                  <Route path='/list'>
                    <RoutingContext.Provider value={{prefix: '/list'}}>
                      <List />
                      <Switch>
                        <Route exact path='/list/group/:groupId' component={GroupRoute} />
                        <Route exact path='/list/group/:groupId/signup' component={GroupSignupRoute} />
                        <Route exact path='/list/group/:groupId/signup/success' component={GroupSignupSuccessRoute} />
                        <Route exact path='/list/group/:groupId/inquire' component={GroupInquiryRoute} />
                        <Route exact path='/list/group/:groupId/contact' component={GroupContactRoute} />
                      </Switch>
                    </RoutingContext.Provider>
                  </Route>
                  <Route>
                    <Redirect to='/map' />
                  </Route>
                </Switch>
              </Filters>
              <ListSwitcher />
            </Router>
          </Location>
        </Config>
      </ToastProvider>
    </div>
  );
}